<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DropdownDefault from "@/components/dropdown-default";

import { liveMethods, notificationMethods } from "@/state/helpers";

import FormLives from "@/components/forms/form-lives/index";

/**
 * Live Classes component
 */
export default {
  page: {
    title: "Gerenciar Aulas ao Vivo",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FormLives,
    DropdownDefault
  },
  data() {
    return {
      tableData: [],      
      editingLive: {},
      loading: true,
      title: "Gerenciar Aulas ao Vivo",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Gerenciar Aulas ao Vivo",
          active: true,
        },
      ],
      showModal: false,
      tableHighlighted: 0,
      totalRows: 1,
      rows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fieldAliases: {
        id: "#",
        title: "Título",
        creationDate: "Data de Criação",
        date: "Data da Aula",
        hour: "Hora da Aula",
        actions: "Ações",
      },
    };
  },
  created() {
    this.loadAll();
  },
  computed: {
    fields() {
      return [
        {
          key: "id",
          sortable: true,
          label: this.fieldAliases.id,
          thStyle: { width: "8%" },
        },
        {
          key: "title",
          sortable: true,
          label: this.fieldAliases.title,
          thStyle: { width: "30%" },
        },
        {
          key: "created_at",
          sortable: true,
          label: this.fieldAliases.creationDate,
        },
        { key: "date", sortable: true, label: this.fieldAliases.date },
        { key: "hour", sortable: true, label: this.fieldAliases.hour },
        {
          key: "actions",
          sortable: true,
          label: this.fieldAliases.actions,
          thStyle: { width: "110px" },
        },
      ];
    },
  },
  mounted() {
    this.totalRows = this.items.length;
  },
  methods: {
    ...liveMethods,
    ...notificationMethods,
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    handleNewCad(value) {
      this.showModal = value;
    },
    
    async loadAll() {
      this.loading = true;
      let registros = [];   
      registros = await this.getAllLive();
      this.tableData = registros?.data;
      this.loading = false;
      this.totalRows = this.tableData.length;
      this.rows = this.tableData.length;
    },

    edit(obj) {
      this.showModal = true;
      setTimeout(() => {
        this.editingLive = obj;        
      }, 200);
    },

    async exclude(obj) {      
      await this.deleteLive(obj.id);
      this.loadAll();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
      :addText="'Adicionar Nova Aula ao Vivo'"
      @newCad="handleNewCad"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Aulas ao Vivo</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar
                    <b-form-select
                      class="form-control form-control-sm form-select form-select-sm"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                    Registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Digite algo..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>
              <b-table
                v-if="!loading"
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(status)="obj">
                  <div class="actions-cell">
                    <span
                      class="badge ms-1"
                      :class="
                        obj.item.status == 'A' ? 'bg-success' : 'bg-warning'
                      "
                    >
                      <h6 class="m-0">
                        {{ obj.item.status == "A" ? "Ativo" : "Inativo" }}
                      </h6>
                    </span>
                  </div>
                </template>
                <!-- Coluna "actions" personalizada -->
                <template #cell(actions)="obj">
                  <div class="actions-cell">
                    <DropdownDefault @edit="edit(obj.item)" @exclude="exclude(obj.item)"></DropdownDefault>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="showModal"
      id="modal-dialog"
      title="Cadastrar Aula ao Vivo"
      title-class="font-18"
      hide-footer
    >
      <FormLives
        :parent="this"
        :editingLive="editingLive"
        @newCad="handleNewCad"
        @newCadSuccess="loadAll"
      ></FormLives>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>
