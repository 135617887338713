<script>
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { liveMethods, notificationMethods, formatDateForInput } from "@/state/helpers";
import Multiselect from "vue-multiselect";

import FormButtonSalvar from "@/components/forms/form-button-salvar/index";

/**
 * Live Classes Form component
 */
export default {
  page: {
    title: "Formulário de Lives",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { FormButtonSalvar, Multiselect },
  props: ["editingLive"],
  data() {
    return {
      form: {
        title: "",
        date: "",
        hour: "",
        type: "",
        link_embed: "",
        observations: "",
      },         
      courses: [
        {
          key: 'Curso de Programação',
          value: 1,
        },
        {
          key: 'Curso de Designer / UX',
          value: 2,
        }
      ],         
      types:
      [
        {
          key: 'YOUTUBE',
          id: 1,
        }
      ],
      submitted: false,
      loading: false,
      submit: false,
    };
  },
  validations: {
    form: {
      title: { required },
      date: { required },
      hour: { required },
      type: { required },
      courses: { required },
      link_embed: { required },
    },
  },
  methods: {
    ...liveMethods,
    ...notificationMethods,
    async formSubmit() {
      this.submitted = true;
      this.loading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const model = { ...this.form, type_id: this.form.type.id, date: new Date(this.form.date).toISOString().split('T')[0]}
        await this.saveLive(model).then((live) => {
          this.emitCadSuccess(live);
          this.emitCad(false);
          this.submitted = false;          
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    emitCad(event) {
      this.$emit("newCad", event);
    },
    emitCadSuccess(event) {
      this.$emit("newCadSuccess", event);
    },
    appendForm(live) {
      this.form = { ...live, date: formatDateForInput(live.date) };
    },
  },
  watch: {
    editingLive: function (live) {
      this.appendForm(live);
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="title"
          >Título <span class="required">*</span></label
        >
        <input
          id="title"
          v-model="form.title"
          type="text"
          class="form-control"
          placeholder="Digite o título"
          :class="{
            'is-invalid': submitted && $v.form.title.$error,
          }"
        />
        <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
          <span v-if="!$v.form.title.required"
            >Por favor, digite o título.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <label class="form-label" for="class-date"
          >Data da Aula <span class="required">*</span></label
        >
        <b-form-input
          type="date"
          v-model="form.date"
          id="class-date"
          :class="{
            'is-invalid': submitted && $v.form.date.$error,
          }"
        ></b-form-input>
        <div
          v-if="submitted && $v.form.date.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.date.required"
            >Por favor, digite a data da aula.</span
          >
        </div>
      </div>
      <div class="col-12 col-md-6">
        <label class="form-label" for="class-hour"
          >Horário da Aula <span class="required">*</span></label
        >
        <b-form-input
          type="time"
          v-model="form.hour"
          id="class-hour"
          value=""
          :class="{
            'is-invalid': submitted && $v.form.hour.$error,
          }"
        ></b-form-input>
        <div
          v-if="submitted && $v.form.hour.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.hour.required"
            >Por favor, digite o horário da aula.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="type"
          >Modelo <span class="required">*</span></label
        >
        <multiselect
          v-model="form.type"
          :options="types"
          placeholder="Selecione o modelo"
          label="key"
          name="type"
          track-by="id"
          :class="{
            'is-invalid': submitted && $v.form.type.$error,
          }"
        ></multiselect>
        <div
          v-if="submitted && $v.form.type.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.type.required"
            >Selecione o modelo.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="link_embed"
          >Link para Incorporar (Youtube) <span class="required">*</span></label
        >
        <input
          id="link_embed"
          v-model="form.link_embed"
          type="text"
          class="form-control"
          placeholder="Digite o link do vídeo"
          :class="{
            'is-invalid': submitted && $v.form.link_embed.$error,
          }"
        />
        <div v-if="submitted && $v.form.link_embed.$error" class="invalid-feedback">
          <span v-if="!$v.form.link_embed.required">Por favor, insira o link do vídeo.</span>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="courses"
          >Cursos <span class="required">*</span></label
        >
        <multiselect
          v-model="form.courses"
          :options="courses"
          placeholder="Selecione os cursos"
          label="key"
          name="courses"
          track-by="value"
          multiple
          :class="{
            'is-invalid': submitted && $v.form.courses.$error,
          }"
        ></multiselect>
        <div
          v-if="submitted && $v.form.courses.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.courses.required"
            >Selecione ao menos 1 curso.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col">
        <label class="form-label" for="textarea"
          >Observações {{ form.observations?.length }}/300</label
        >
        <textarea
          v-model="form.observations"
          maxlength="300"
          class="form-control"
          name="textarea"
          rows="5"
          placeholder="Observações"
        ></textarea>
      </div>
    </div>
    <br />
    <div class="mt-2 text-end">
      <b-button variant="light" @click="emitCad(false)">Fechar</b-button>
      <FormButtonSalvar 
        :parent="this"
        :loading="loading"
        :text="form?.id ? 'Salvar' : 'Cadastrar'"
      ></FormButtonSalvar>
    </div>
  </form>
</template>
